<template>
  <div  class="px-15px px-lg-25px">
    <section class="py-8 bg-soft-primary">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 mx-auto text-center">
            <h1 class="mb-0 fw-700">{{ $t('dian-pu-sheng-ji-tao-can-lie-biao') }}</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="py-4 py-lg-5">
      <div class="container">
        <div class="row row-cols-xxl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 gutters-10 justify-content-center">
          <div class="col" v-for="(item, i) in packageList" :key="i">
            <div class="card overflow-hidden">
              <div class="card-body">
                <div class="text-center mb-4 mt-3">
                  <img class="mw-100 mx-auto mb-4" :src="logoObj[`logo${i+1}`] || packageUrl" height="100">
                  <h5 class="mb-3 h5 fw-600">{{item.LevelName}}</h5>
                </div>
                <ul class="list-group list-group-raw fs-15 mb-5">
                    <li class="list-group-item py-2">
                      <i class="las la-check text-success mr-2"></i>
                       {{item.GoodsLimit}} {{ $t('chan-pin-shang-chuan-xian-zhi') }} 
                    </li>
                    <li class="list-group-item py-2">
                      <i class="las la-check text-success mr-2"></i>
                      {{ $t('zui-da-li-run') }} {{ item.ProfitRate }}%
                    </li>
                </ul>
                <div class="mb-5 d-flex align-items-center justify-content-center">
                  <span class="display-4 fw-600 lh-1 mb-0" v-if="item.UpgradeMoney == 0">{{ $t('mian-fei') }}</span>
                  <span class="display-4 fw-600 lh-1 mb-0" v-else>${{(item.UpgradeMoney)}}</span>
                  <span class="text-secondary border-left ml-2 pl-2">365<br>{{ $t('tian') }}</span>
                </div>

                <div class="text-center">
                  <button class="btn btn-primary fw-600" v-if="item.UpgradeMoney == 0" @click="submit(item)">{{ $t('mian-fei-tao-can') }}</button>
                  <button class="btn btn-primary fw-600" v-else @click="submit(item)">{{ $t('gou-mai-tao-can') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Popup v-model="showPopup">
      <div class="popup-box " id="wallet_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('xuan-ze-fu-kuan-fang-shi') }}</h5>
              <button type="button" class="close" @click="cancel"></button>
            </div>
            <form class="" >
              <div class="modal-body gry-bg px-3 pt-3">
               
                <div class="row">
                  <div class="col-md-4">
                      <label>{{ $t('fu-kuan-fang-shi') }} <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-8">
                    <div class="mb-3">
                      <a-select v-model="form.payType" :placeholder="$t('qing-xuan-ze-3')" class="form-control clear" size="large">
                        <a-select-option  :value="item.value" v-for="(item, i) in payTypeList" :key="i">{{ item.label }}</a-select-option>
                      </a-select>
                    </div>
                  </div>
                  </div>
                  <div class="form-group text-right">
                    <button type="submit" class="btn btn-sm btn-primary transition-3d-hover mr-1">{{ $t('que-ding') }}</button>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Popup } from 'vant'
import pageBar from '@/components/page'
import { shopLevelList, shopUpgrade } from '@/api/seller'
import { resetPrice } from '@/utils/common'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      showPopup: false,
      form: {
        deliverStatus: '',
        payStatus: '',
        payType: ''
      },
      statusList1: [],
      statusList2: [],
      hasPackage: true,
      packageList: [],
      packageUrl: require('../../../assets/imgs/package.png'),
      logoObj: {
        logo1: require('../../../assets/imgs/package.png'),
        logo2: require('../../../assets/imgs/package2.png'),
        logo3: require('../../../assets/imgs/package3.png'),
      },
      payTypeList: [
        {
          value: '1',
          label: 'Paypal'
        },
        {
          value: '2',
          label: this.$t('wei-xin')
        },
        {
          value: '3',
          label: this.$t('zhi-fu-bao')
        },
      ],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    resetPrice,
    init() {
      shopLevelList().then(res => {
        this.packageList = res.data.Items
      })
    },
    openModal() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    submit(data) {
      let form = new FormData()
      form.append('ShopLevelID', data.ID)
      shopUpgrade(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('sheng-ji-cheng-gong'))
          
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>